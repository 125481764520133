<template>
  <div class="page">
    <div class="container">
      <div class="header">
        <el-image :src="require('@/assets/logo.png')" @click="toHome" style="cursor: pointer"></el-image>
        <div class="header__content">
          <a href="mailto:exam@holowits.com" class="contact">Contact Us</a>
        </div>
      </div>
      <div class="container__body">
        <h1 class="login_title">Reset Password</h1>
        <el-form :model="loginForm" class="login_form" :rules="rules" ref="loginForm">
          <el-form-item prop="password" class="has-tips-form-item">
            <div class="flex-content">
              <el-input class="password-input" placeholder="New Password" v-model="loginForm.password" show-password/>
              <div>
                <el-popover placement="bottom-start" width="236" popper-class="cus-popper" :offset="-10">
                  <div class="tips-content">
                    <p>For your account security your new password must:</p>
                    <ul>
                      <li>Contain at least 8 characters.</li>
                      <li>Contain any combination of letters, digits, and special characters.</li>
                    </ul>
                  </div>
                  <div class="tips-btn" slot="reference"></div>
                </el-popover>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="confirmPwd">
            <el-input class="password-input" placeholder="Confirm Password" v-model="loginForm.confirmPwd" show-password/>
          </el-form-item>
          <el-form-item prop="code">
            <div class="flex-content">
              <el-input placeholder="Verification Code" v-model="loginForm.code"/>
              <div class="verification-code-image" @click="getCodeImage">
                <img :src="imageCode" alt="">
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="flex-btn-group">
          <el-button type="primary" class="submit_btn" plain @click="cancelSubmit">Cancel</el-button>
          <el-button type="primary" class="submit_btn" @click="submitRestPwd">Submit</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {login, getCaptcha,forgetPassword} from '@/api/login';
  import {randomNum} from '@/utils/commons';

  export default {
    name: 'index',
    data() {
      return {
        loading: false,
        loginForm: {
          account: '',
          password: '',
          code: '',
          key: randomNum(24, 16),
          verifyCode:'',
          confirmPwd:'',
          tenant: 'cGFydG5lcg==',
          grantType: 'password',
        },
        imageCode: '',
        rules: {
          account: [{required: true, message: 'Email cannot be empty.', trigger: 'blur'}],
          password: [{required: true, message: 'Password cannot be empty.', trigger: 'blur'}],
          confirmPwd: [{required: true, message: 'Confirm Password cannot be empty.', trigger: 'blur'}],
          code: [{required: true, message: 'Verification Code cannot be empty.', trigger: 'blur'}],
        },
      };
    },
    mounted() {
      this.getCodeImage();
      this.loginForm.verifyCode = this.$route.query.verifyCode;
    },
    methods: {
      toHome() {
        this.$router.replace({name: 'home'});
      },

      getCodeImage() {
        getCaptcha(this.loginForm.key).then(response => {
          const res = response.data;
          return ('data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '',),));
        }).then(res => {
          this.imageCode = res;
        }).catch(e => {
          if (e.toString().indexOf('429') !== -1) {
            this.$message({
              message: this.$t('tips.tooManyRequest'),
              type: 'error',
            });
          } else {
            this.$message({
              message: this.$t('tips.getCodeImageFailed'),
              type: 'error',
            });
          }
        });
      },

      submitRestPwd(){
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            if(this.loginForm.password != this.loginForm.confirmPwd){
              this.$message({
                message: this.$t('tipInfo.errorPwd'),
                type: 'error',
              });
              return
            }
            this.loading = true;
            forgetPassword(this.loginForm).then(res=>{
              if(res.data.isSuccess){
                this.$router.push('/login');
              }else{
                this.$message({
                  message: this.$t('tipInfo.submitFailed'),
                  type: 'error',
                });
              }
            })
          }
        })
      },
      cancelSubmit(){
        this.$refs.loginForm.clearValidate()
        this.$refs.loginForm.resetFields()
        this.$router.push('/login');
      },
    },
  };
</script>

<style scoped lang="less">
  @import "login";
</style>
<style lang="scss">
  .cus-popper {
    transform: translateY(-8px);
  }
</style>
